<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="productsManager.products"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="addNewRow"
              v-on:delete="deleteRows"
              v-model:showDelete="statuses[status].showDelete"
              v-model:showAdd="statuses[status].showAdd"
              :methods="
                statuses[
                  modules.production || type == 'products'
                    ? status
                    : 'packaging'
                ].methods
              "
              :path="'products::' + type + '::*'"
              :export-url="getUrl(statuses[status].id, true)"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="productsManager.products"
              v-model:select-all="selectAll"
              :firstRowComponent="firstRowComponent"
              firstRowKey="status"
              type="products"
              v-on:update:val="valUpdated"
              v-on:selected="(e) => (selectedItems = e)"
              v-on:order="orderBy"
              v-on:pin="pin"
              v-on:loadMore="loadMore"
              :order="order"
              :fix-height="true"
              :key="dynamicKey"
              :load-in-process="loadInProcess"
            />
            <div
              class="col-12 text-end regular-12 text-gray"
              v-if="showedNumber"
            >
              1 - {{ showedNumber }} / {{ totalNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="addNewModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-xl
          modal-fullscreen-lg-down
          modal-dialog-centered
          modal-dialog-scrollable
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              {{ $t("products.addNewProduct") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              :aria-label="$t(`orderChange.close`)"
            ></button>
          </div>
          <div class="modal-body">
            <div class="text-start m-2 custom-input">
              <div class="row">
                <div class="col-6 col-md-4 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("products.productName")
                  }}</label>
                  <input class="form-control" v-model="newProduct.name" />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("products.category")
                  }}</label>
                  <v-select
                    v-model="newProduct.category_id"
                    :options="this.categories"
                    label="name"
                    taggable
                    :create-option="
                      (name) => ({ name: name, id: '____' + name })
                    "
                    :placeholder="$t(`products.selectACategory`)"
                    :reduce="(name) => name.id"
                    append-to-body
                  />
                </div>
                <div class="col-6 col-md-4 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("products.productSerial") }}
                  </label>
                  <input class="form-control" v-model="newProduct.serial" />
                  <button
                    class="btn-small btn-outline-light"
                    v-on:click="nextSerial()"
                  >
                    <BaseIcon name="check" />
                  </button>
                </div>
                <div
                  v-if="this.type === 'materials'"
                  class="col-12 col-md-4 mb-3"
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("products.unit")
                  }}</label>
                  <v-select
                    :options="[
                      { id: 1, name: 'Gram' },
                      { id: 2, name: 'Meter' },
                      { id: 3, name: 'Square meter' },
                      { id: 4, name: 'Piece' },
                      { id: 5, name: 'Bundle' },
                    ]"
                    v-model="newProduct.unit"
                    label="name"
                    :placeholder="$t(`products.selectAUnit`)"
                    :reduce="(c) => c.id"
                    append-to-body
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3 class="bold-19 mb-3">
                  {{ $t("products.manageVariants") }}
                </h3>
              </div>
              <div class="col-5 col-md-4 mb-3 scroll-filters">
                <div
                  v-for="cs in characteristics.filter(
                    (e) =>
                      e.is_variant &&
                      e[type === 'materials' ? 'in_materials' : 'in_products']
                  )"
                  :key="cs.id"
                >
                  <div class="row align-items-center filter-top">
                    <div class="col-6">
                      <b>{{ cs.name }}</b>
                    </div>
                    <div class="col-6">
                      <input
                        type="search"
                        class="form-control py-0"
                        v-model="cs.filter"
                        :placeholder="`Search ${cs.name.toLowerCase()}..`"
                      />
                    </div>
                  </div>
                  <div class="filter-container">
                    <div
                      class="y-scroll height-126 ps-2 my-1 pb-2"
                      v-if="cs.characteristics_values?.length > 0"
                    >
                      <div
                        class="row"
                        v-for="value in cs.characteristics_values.filter(
                          (e) =>
                            e.value
                              .toString()
                              .toLowerCase()
                              .indexOf(cs.filter.toLowerCase()) > -1
                        )"
                        :key="value.id"
                      >
                        <label class="cstm-check dropdown-item">
                          <span class="text-black mx-2 regular-14">{{
                            value.value
                          }}</span>
                          <input
                            type="checkbox"
                            value="7"
                            v-model="possibleVariantCharasterics[value.id]"
                            v-on:change="
                              addPossibleVariant(
                                $event.currentTarget.checked,
                                cs,
                                value
                              )
                            "
                          /><span class="checkmark select mx-2"></span>
                        </label>
                      </div>
                      <div class="row" v-if="cs.filter?.length > 0">
                        <label class="dropdown-item text-center">
                          <span class="text-black mx-2 regular-14">
                            <button
                              class="btn btn-primary"
                              v-on:click="addNewCharactheristicsValue(cs)"
                            >
                              {{
                                $t(`products.addAsNew`, { filter: cs.filter })
                              }}
                            </button>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7 col-md-8 mb-3 scroll-filters">
                <label
                  class="cstm-check dropdown-item py-2"
                  v-for="(variant, i) in possibleVariants"
                  :key="i"
                >
                  <div class="row text-black regular-14">
                    <div class="col-auto">
                      <input type="checkbox" v-model="variant.checked" /><span
                        class="checkmark select mx-2"
                      ></span>
                    </div>
                    <div
                      class="col-3 col-md-2"
                      v-for="s in variant.values"
                      :key="s"
                    >
                      {{ s.value }}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'loading-spin': loadAddBtn }"
              v-on:click="addNew"
              :disabled="loadAddBtn"
            >
              <span>{{ $t("products.addNewProduct") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      ref="multipleEditModal"
      :title="`Edit ${selectedItems?.length} products`"
      :text="`<p>You will edit<b> ${selectedItems?.length}</b> products</p>
  <p>You will edit all selected rows.</p>`"
      cancel-button="Modify only edited row"
      :ok-button="`Edit selected ${selectedItems?.length} rows`"
      v-on:success="multipleEdit"
    />
    <NewManualIncoming
      v-model:show="showManualIncoming"
      ref="manualIncoming"
      v-on:newItem="deleteIncomeItems"
    />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`outgoing.noWarehouseSelected`)"
      text="please select a warehouse before adding a new income!"
      :show-ok="false"
      cancel-button="Ok"
    />
    <CopyProducts
      v-model="selectedItems"
      ref="copyProducts"
      :copy-rows="copyableRows"
      :characteristics="
        characteristics.filter((e) => e.is_variant && e.in_products)
      "
    />
    <ManualProductRecommendations
      ref="manualProductRecommendations"
      :categories="categories"
      v-model="selectedItemId"
      :values="selectedItemValues"
    />
  </div>
</template>

<script>
import TopBar from "../../components/lists/TopBar";
import { defineAsyncComponent, markRaw } from "vue";
import { useStore } from "vuex";
import http from "../../modules/http";
import products from "../../modules/products";
import ws from "../../modules/ws";
import ConfirmModal from "../../components/modals/ConfirmModal";
import store from "../../store";
import DynamicTable from "../../components/lists/DynamicTable";
import NewManualIncoming from "../../components/modals/NewManualIncoming";
import BaseIcon from "../../components/icons/BaseIcon.vue";
import CopyProducts from "../../components/modals/CopyProducts.vue";
import ManualProductRecommendations from "../../components/modals/ManualProductRecommendations.vue";
import tenant from "../../modules/tenant";

const bootstrap = require("bootstrap");

export default {
  name: "Products",
  data() {
    let store = useStore();
    return {
      store: store,
      firstLoad: true,
      tableKey: 0,
      dynamicKey: 0,
      addNewModal: undefined,
      selectedItems: [],
      filterOptions: [],
      noReloadFilters: false,
      orderItems: {},
      selectedCharacteristics: {},
      characteristics: [],
      possibleVariants: [],
      categories: [],
      allCategories: [],
      copyableRows: [],
      selectedItemId: null,
      selectedItemValues: null,
      possibleVariantCharasterics: {},
      newCharastericsValue: undefined,
      loadAddBtn: false,
      showManualIncoming: false,
      newProduct: {
        category_id: null,
        name: "",
        serial: "",
      },
      rows: [],
      selectedLineOption: null,
      lineOptions: [],
      selectAll: false,
      firstRowComponent: markRaw(
        defineAsyncComponent(() =>
          import("../../components/lists/listItems/ProductFirstRow")
        )
      ),
      loadId: undefined,
      productsManager: products,
      statuses: {
        packaging: {
          id: 3,
          showDelete: true,
          showAdd: true,
          methods: [
            {
              title: "Add variant",
              method: () => {
                this.newProduct.name = this.selectedItems[0].name_en;
                this.newProduct.serial = this.selectedItems[0].serial;
                this.newProduct.category_id = this.selectedItems[0].category_id;
                this.addNewRow();
              },
            },
            {
              title: "Create income",
              method: this.createIncome,
              always: true,
            },
            {
              title: "Open all",
              method: this.openAll,
              always: true,
            },
          ],
        },
        active: {
          id: 1,
          showDelete: false,
          showAdd: false,
          methods: [
            {
              title: "Set draft",
              method: () => {
                this.selectedEditMethod = {
                  val: 3,
                  row: "status",
                  id: this.selectedItems[0].id,
                  item: this.selectedItems[0],
                };
                this.multipleEdit();
              },
            },
            {
              title: "Set archive",
              method: () => {
                this.selectedEditMethod = {
                  val: 2,
                  row: "status",
                  id: this.selectedItems[0].id,
                  item: this.selectedItems[0],
                };
                this.multipleEdit();
              },
            },
            {
              title: "Add variant",
              method: () => {
                this.newProduct.name = this.selectedItems[0].name_en;
                this.newProduct.serial = this.selectedItems[0].serial;
                this.newProduct.category_id = this.selectedItems[0].category_id;
                this.addNewRow();
              },
            },
            {
              title: "Create income",
              method: this.createIncome,
              always: true,
            },
            {
              title: "Copy",
              method: this.openCopy,
            },
            {
              title: "Open all",
              method: this.openAll,
              always: true,
            },
          ],
        },
        archived: {
          id: 2,
          showDelete: false,
          showAdd: false,
          methods: [
            {
              title: "Set active",
              method: () => {
                this.selectedEditMethod = {
                  val: 1,
                  row: "status",
                  id: this.selectedItems[0].id,
                  item: this.selectedItems[0],
                };
                this.multipleEdit();
              },
            },
            {
              title: "Create income",
              method: this.createIncome,
              always: true,
            },
            {
              title: "Copy",
              method: this.openCopy,
            },
            {
              title: "Open all",
              method: this.openAll,
              always: true,
            },
          ],
        },
        draft: {
          id: 3,
          showDelete: true,
          showAdd: true,
          methods: [
            {
              title: "Set active",
              method: () => {
                this.selectedEditMethod = {
                  val: 1,
                  row: "status",
                  id: this.selectedItems[0].id,
                  item: this.selectedItems[0],
                };
                this.multipleEdit();
              },
            },
            {
              title: "Set archive",
              method: () => {
                this.selectedEditMethod = {
                  val: 2,
                  row: "status",
                  id: this.selectedItems[0].id,
                  item: this.selectedItems[0],
                };
                this.multipleEdit();
              },
            },
            {
              title: "Add variant",
              method: () => {
                this.newProduct.name = this.selectedItems[0].name_en;
                this.newProduct.serial = this.selectedItems[0].serial;
                this.newProduct.category_id = this.selectedItems[0].category_id;
                this.addNewRow();
              },
            },
            {
              title: "Create income",
              method: this.createIncome,
              always: true,
            },
            {
              title: "Copy",
              method: this.openCopy,
            },
            {
              title: "Open all",
              method: this.openAll,
              always: true,
            },
          ],
        },
      },
      order: {
        row: null,
        desc: true,
      },
      filtersTimeOut: null,
      selectedEditMethod: null,
      canLoadMore: true,
      loadInProcess: false,
      page: 0,
      newCategory: {},
      showedNumber: null,
      totalNumber: null,
    };
  },
  computed: {
    modules() {
      return this.store.state.modules;
    },
    operations() {
      return this.store.state.operations;
    },
    pricePartners() {
      return this.store.state.pricePartners;
    },
    type() {
      return this.$router.currentRoute.value.meta?.type;
    },
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    languages() {
      return this.store.state.languages;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    status() {
      return this.$route.params.status;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    filters() {
      if (
        Object.keys(this.store.state.filters).indexOf(
          "products_" + this.type + "_" + this.status
        ) < 0
      ) {
        return {};
      }
      return this.store.state.filters[
        "products_" + this.type + "_" + this.status
      ];
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    path() {
      return this.$route.path;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    q() {
      return this.store.state.topBar.q;
    },
    date() {
      return this.store.state.topBar.date;
    },
    tags() {
      return this.store.state.tags.products;
    },
  },
  watch: {
    path() {
      if (this.status === undefined) {
        return 0;
      }
      this.page = 0;
      this.firstLoad =
        this.filterPage !== "products_" + this.type + "_" + this.status;
      http
        .fetch(`/products/${this.type}/categories?language=en`)
        .then((data) => {
          this.categories = [];
          this.setUpCategories({ categories: data.data });
        });
      this.setProducts(this.statuses[this.status].id);
    },
    date: {
      deep: true,
      handler() {
        this.page = 1;
        this.setProducts(this.statuses[this.status].id);
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.setProducts(this.statuses[this.status].id);
      }, 450);
    },
    filters: {
      deep: true,
      handler() {
        if (this.noReloadFilters) {
          return;
        }
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.page = 1;
          this.setProducts(this.statuses[this.status].id);

          this.categoryFilters();
        }, 450);
      },
    },
  },

  mounted() {
    http.fetch(`/products/${this.type}/categories?language=en`).then((data) => {
      this.setUpCategories({ categories: data.data });
    });
    this.setProducts(this.statuses[this.status].id);

    http.fetch("/manufacturing/operations/all").then((data) => {
      this.$store.commit("setOperations", data.data);
    });

    http.fetch("/crm/partners/has-price").then((data) => {
      this.$store.commit("setPricePartners", data);
    });

    ws.init();

    ws.subscribe("add", "product", (e) => {
      if (
        e.session !== this.sessionId &&
        this.status === "draft" &&
        Object.keys(this.filters)?.length === 0
      ) {
        this.productsManager.addProduct(e.object, true);
      }
    });

    ws.subscribe("modify", "product", (e) => {
      let obj = this.productsManager.byProductId[e.objectId];
      if (obj !== undefined) {
        if (obj[e.rowKey] !== e.value) {
          obj[e.rowKey] = e.value;
        }
      }
    });
    ws.subscribe("modify", "productVariant", (e) => {
      let obj = this.productsManager.byVariantId[e.objectId];
      if (obj !== undefined) {
        if (e.rowKey === "components") {
          this.productsManager.setComponents(e.value, e.objectId);
        } else if (obj[e.rowKey] !== e.value) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    window.apps.subscribe("manualProductRecommendations:show", (id, values) => {
      this.selectedItemId = id;
      this.selectedItemValues = values;
      this.$refs.manualProductRecommendations.showModal();
    });

    window.apps.subscribe("print:operations", (id) => {
      let items = "&id[]=" + id;
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.type !== 3 && item.type !== 4) {
            continue;
          }
          items += "&id[]=" + item.id;
        }
      }
      window.open(
        `${tenant.getApiBaseUrl()}/products/manufacturing-operations/pdf?_token=${
          store.state.token
        }${items}`,
        "_blank"
      );
    });

    window.apps.subscribe("print:ingredientCost", (id) => {
      let items = "&id[]=" + id;
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.type !== 3 && item.type !== 4) {
            continue;
          }
          items += "&id[]=" + item.id;
        }
      }
      window.open(
        `${tenant.getApiBaseUrl()}/products/ingredient-cost/pdf?_token=${
          store.state.token
        }${items}`,
        "_blank"
      );
    });

    window.apps.subscribe("print:ingredients", (id) => {
      let items = "&id[]=" + id;
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.type !== 3 && item.type !== 4) {
            continue;
          }
          items += "&id[]=" + item.id;
        }
      }
      window.open(
        `${tenant.getApiBaseUrl()}/products/ingredients/pdf?_token=${
          store.state.token
        }${items}`,
        "_blank"
      );
    });

    window.apps.subscribe("setValue:manualIncoming", (id, amount) => {
      amount = Number.parseInt(amount);
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.type !== 2 && item.type !== 1) {
            continue;
          }
          if (item.id === id) {
            this.orderItems[item.id] = item;
            continue;
          }
          item.create_income = amount;
          this.orderItems[item.id] = item;
        }
      } else {
        this.orderItems[id] = this.productsManager.byVariantId[id];
      }
    });

    window.apps.subscribe("changeValue:manualIncoming", (id, amount) => {
      if (this.selectedItems.length > 1) {
        for (const item of this.selectedItems) {
          if (item.type !== 2 && item.type !== 1) {
            continue;
          }
          if (item.id === id) {
            this.orderItems[item.id] = item;
            continue;
          }
          if (item.create_income === undefined || item.create_income === null) {
            item.create_income = amount > 0 ? amount : 0;
          } else {
            if (item.create_income + amount > -1) {
              item.create_income += amount;
            }
          }
          this.orderItems[item.id] = item;
        }
      } else {
        this.orderItems[id] = this.productsManager.byVariantId[id];
      }
    });
  },
  methods: {
    getUrl(status, exports = false) {
      let url = `/products/${this.type}${
        exports ? "/export/{type}" : ""
      }?status=${status}&order[desc]=${this.order.desc ? 1 : 0}&order[row]=${
        this.order.row
      }&page=${exports ? 1 : this.page}${this.q ? "&q=" + this.q : ""}${
        this.date[0] ? "&from=" + this.date[0].toISOString().split("T")[0] : ""
      }${
        this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : ""
      }`;

      url = this.setFilterUrl(url);
      return url;
    },
    openCopy() {
      this.$refs.copyProducts.showModal();
    },
    openAll() {
      let status = !this.productsManager.products[0].openVariants;
      for (const product of this.productsManager.products) {
        product.openVariants = status;
        if (product.variants) {
          for (const variant of product.variants) {
            variant.openVariants = status;
          }
        }
      }
    },
    createIncome() {
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.showManualIncoming = true;
        this.$refs.manualIncoming.setCharacteristics(this.characteristics);
        this.$refs.manualIncoming.setEmpty();
        console.log(this.orderItems);
        for (const key in this.orderItems) {
          if (this.orderItems[key].create_income < 1) {
            continue;
          }
          this.$refs.manualIncoming.addProductVariant(
            this.orderItems[key],
            this.orderItems[key].create_income
          );
        }
      }
    },
    deleteIncomeItems() {
      this.orderItems = [];
    },
    loadMore() {
      if (this.canLoadMore) {
        this.loadInProcess = true;
        this.canLoadMore = false;
        this.setProducts(this.statuses[this.status].id, true);
      } else {
        this.loadInProcess = false;
      }
    },
    multipleEdit() {
      if (this.selectedEditMethod === null) {
        return;
      }
      let ids = [];
      let variantIds = [];

      this.selectedEditMethod.item = Object.assign(
        {},
        this.selectedEditMethod.item
      );

      if (this.selectedEditMethod.item.type === products.TYPE_GROUP) {
        this.selectedEditMethod.item.type = products.TYPE_VARIANT;
      }

      if (
        this.selectedEditMethod.item.type === products.TYPE_MULTIPLE_PRODUCT
      ) {
        this.selectedEditMethod.item.type = products.TYPE_PRODUCT;
      }

      for (const item of this.selectedItems) {
        if (
          item.type === this.selectedEditMethod.item.type ||
          (item.type === products.TYPE_MULTIPLE_PRODUCT &&
            this.selectedEditMethod.item.type === products.TYPE_PRODUCT)
        ) {
          ids.push(item.id);
        }
        if (item.type === products.TYPE_PRODUCT) {
          variantIds.push(item.variantId);
        } else if (item.type === products.TYPE_VARIANT) {
          variantIds.push(item.id);
        }
      }

      this.editItems(
        this.selectedEditMethod.val,
        this.selectedEditMethod.row,
        ids,
        this.selectedEditMethod.item,
        variantIds
      );
    },
    valUpdated(val, row, id, item, updateAll) {
      if (this.selectedItems.length > 1) {
        this.$refs.multipleEditModal.showModal();
        this.selectedEditMethod = {
          val: val,
          row: row,
          id: id,
          item: item,
        };
        return;
      }
      this.editItems(val, row, id, item, null, updateAll);
    },

    editItems(val, row, id, item, variantID = null, updateAll = null) {
      if (
        item.type === products.TYPE_PRODUCT ||
        item.type === products.TYPE_MULTIPLE_PRODUCT
      ) {
        if (row === "description") {
          let data = { id: id, description: val };
          http.fetch(
            `/products/edit-description`,
            data,
            false,
            null,
            "Change description"
          );
        } else if (row === "meta") {
          let data = Object.assign({ id: id }, val);
          http.fetch(`/products/edit-meta`, data, false, null, "Change meta");
        } else if (row === "category_id") {
          // add new category, if it not exists yet
          if (val === 0 && this.newCategory !== undefined) {
            let newCategory = this.newCategory;
            this.newCategory = undefined;
            http
              .fetch(
                `/products/${this.type}/categories/add`,
                newCategory,
                false,
                null,
                "Edit category"
              )
              .then((respond) => {
                let rowData = this.rows.filter((e) => e.key === row)[0];
                rowData.values.push({ id: respond.id, value: data.value });
                item[row] = respond.id;

                let data = { id: id, category: respond.id };
                http.fetch(
                  `/products/${this.type}/edit-products`,
                  data,
                  false,
                  null,
                  "Edit category"
                );
              });
          } else {
            let data = { id: id, category: val };
            http.fetch(
              `/products/${this.type}/edit-products`,
              data,
              false,
              null,
              "Edit products"
            );
          }
        } else if (row === "serial") {
          let data = { id: id, serial: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit serials"
          );
        } else if (row === "tags") {
          let data = { id: id, tags: val.flatMap((e) => e.id) };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit tags"
          );
        } else if (row === "product_manufacturing_operations") {
          let data = {
            id: id,
            lock: this.productsManager.byProductId[id]
              .is_manufacturing_operations_locked,
            operations: val.flatMap((e) => {
              let d = {
                id: e.id,
                parallel: e?.parallel ?? 0,
                time: e?.time ?? 0,
              };
              if (e.description) {
                d.description = e.description;
              }
              if (e.step_id) {
                d.step_id = e.step_id;
              }
              return d;
            }),
          };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/manufacturing-operations?`),
            data,
            true,
            null,
            "Edit operations"
          );
        } else if (row === "sales_channel") {
          let data = { id: id, sales_channel: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "unit") {
          let data = { id: id, unit: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "order") {
          let data = { id: id, order: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "release_data") {
          let data = { id: id, release_data: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "preorder") {
          let data = { id: id, preorder: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "end_date") {
          let data = { id: id, end_date: val };
          http.fetch(
            `/products/${this.type}/edit-products`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "image") {
          const product = this.productsManager.byProductId[id];
          const old = product.image;
          product.image = val;
          for (const variant of product.variants) {
            if (
              variant.image === null ||
              variant.image === undefined ||
              variant.image === old
            ) {
              variant.image = val;
            }
          }
        } else if (row.indexOf("name_") > -1) {
          let data = { id: id, name: val, language: row.replace("name_", "") };
          http.fetch(
            `/products/edit-product-translation`,
            data,
            false,
            null,
            "Edit translations"
          );
        }
        if (Array.isArray(id)) {
          for (const id1 of id) {
            this.productsManager.byProductId[id1][row] = val;
          }
        }
      }
      if (
        item.type === products.TYPE_GROUP ||
        item.type === products.TYPE_MULTIPLE_PRODUCT
      ) {
        let ids = item.variants.flatMap((e) => {
          if (e.variants !== undefined) {
            return e.variants.flatMap((s) => s.id);
          }
          return e.id;
        });
        if (row === "serial") {
          let data = { id: ids, serial: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "mpn") {
          let data = { id: ids, mpn: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "upc") {
          let data = { id: ids, upc: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "ean") {
          let data = { id: ids, ean: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "preorder") {
          let data = { id: ids, preorder: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "end_date") {
          let data = { id: ids, end_date: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "condition") {
          let data = { id: ids, condition: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "vendor_package") {
          let data = { id: ids, vendor_package: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "rank") {
          let data = { id: ids, rank: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("ingredients_") === 0) {
          let data = Object.assign({ id: ids }, val);
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/components?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("characteristics_") === 0) {
          let data = {
            id: ids,
            characterics_id: row.replace("characteristics_", ""),
            value: val,
          };
          if (val === 0 && this.newCharastericsValue !== undefined) {
            data.value = this.newCharastericsValue;
            data.add = 1;
            this.newCharastericsValue = undefined;
          }
          http
            .fetch(
              `/products/${this.type}/edit-characterics`,
              data,
              false,
              null,
              "Edit products"
            )
            .then((respond) => {
              if (data.add) {
                let rowData = this.rows.filter((e) => e.key === row)[0];
                rowData.values.push({ id: respond.id, value: data.value });
                item[row] = respond.id;
              }
            });
        } else if (row === "status") {
          let data = { id: ids, status: val };
          http
            .fetch(
              `/products/${this.type}/edit-variant`,
              data,
              false,
              null,
              "Edit products"
            )
            .then((data) => {
              let e = 0;
              for (const eid in data.errors) {
                this.productsManager.byVariantId[eid].errorNotify = true;
                setTimeout(() => {
                  this.productsManager.byVariantId[eid].errorNotify = false;
                }, 2000);

                setTimeout(() => {
                  store.commit("setApiError", {
                    title: this.productsManager.byVariantId[eid].sku,
                    message: data.errors[eid],
                  });
                }, e * 2000);
                e++;
              }
              for (const eid of data.successes) {
                this.productsManager.byVariantId[eid].hideSuccess = true;
              }
            });
        } else if (row === "purchase_price") {
          let data = { id: ids };
          data.purchase = val;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("sell_price_") === 0) {
          let data = Object.assign({ id: ids }, val);
          data.sell = data.amount;
          delete data.amount;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("discount_price_") === 0) {
          let data = Object.assign({ id: ids }, val);
          data.discount = data.amount;
          delete data.amount;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("external_") === 0) {
          let data = Object.assign({}, val);
          data.external_category_id = val.id;
          data.id = ids;
          http.fetch(
            `/products/edit-external`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("minimum_stock_") === 0) {
          let data = {
            id: ids,
            minimum_stock: val,
            warehouse: row.replace("minimum_stock_", ""),
          };
          http.fetch(
            `/products/${this.type}/edit-warehouse`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("is_sale_") === 0) {
          let data = {
            id: ids,
            is_sale: val,
            warehouse: row.replace("is_sale_", ""),
          };
          http.fetch(
            `/products/${this.type}/edit-warehouse`,
            data,
            false,
            null,
            "Edit products"
          );
        }
        if (updateAll) {
          for (const id1 of ids) {
            this.productsManager.byVariantId[id1][row] = val;
          }
        }
      }
      if (variantID === null && item.type === products.TYPE_PRODUCT) {
        id = item.variantId;
      } else if (variantID !== null) {
        id = variantID;
      }
      if (
        item.type === products.TYPE_VARIANT ||
        item.type === products.TYPE_PRODUCT
      ) {
        if (row === "serial") {
          let data = { id: id, serial: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "mpn") {
          let data = { id: id, mpn: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "upc") {
          let data = { id: id, upc: val };
          http.fetch(
            `/products/${this.type}/edit-variant`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "ean") {
          let data = { id: id, ean: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "condition") {
          let data = { id: id, condition: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "preorder") {
          let data = { id: id, preorder: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "end_date") {
          let data = { id: id, end_date: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "vendor_package") {
          let data = { id: id, vendor_package: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row === "rank") {
          let data = { id: id, rank: val };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-variant?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("ingredients_") === 0) {
          let data = Object.assign({ id: id }, val);
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/components?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("characteristics_") === 0) {
          let data = {
            id: id,
            characterics_id: row.replace("characteristics_", ""),
            value: val,
          };
          if (val === 0 && this.newCharastericsValue !== undefined) {
            data.value = this.newCharastericsValue;
            data.add = 1;
            this.newCharastericsValue = undefined;
          }
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http
            .fetch(
              this.setFilterUrl(`/products/${this.type}/edit-characterics?`),
              data,
              false,
              null,
              "Edit products"
            )
            .then((respond) => {
              if (data.add) {
                let rowData = this.rows.filter((e) => e.key === row)[0];
                rowData.values.push({ id: respond.id, value: data.value });
                item[row] = respond.id;
              }
            });
        } else if (row === "image") {
          const product = this.productsManager.byVariantId[id];
          product.image = val;
        } else if (row === "status") {
          let data = { id: id, status: val };
          http
            .fetch(
              `/products/${this.type}/edit-variant`,
              data,
              false,
              null,
              "Edit products"
            )
            .then((data) => {
              let e = 0;
              for (const eid in data.errors) {
                this.productsManager.byVariantId[eid].errorNotify = true;
                setTimeout(() => {
                  this.productsManager.byVariantId[eid].errorNotify = false;
                }, 2000);

                setTimeout(() => {
                  store.commit("setApiError", {
                    title: this.productsManager.byVariantId[eid].sku,
                    message: data.errors[eid],
                  });
                }, e * 2000);
                e++;
              }
              for (const eid of data.successes) {
                this.productsManager.byVariantId[eid].hideSuccess = true;
              }
            });
        } else if (row === "purchase_price") {
          let data = { id: id };
          data.purchase = val;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("sell_price_") === 0) {
          let data = Object.assign({ id: id }, val);
          data.sell = data.amount;
          delete data.amount;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("discount_price_") === 0) {
          let data = Object.assign({ id: id }, val);
          data.discount = data.amount;
          delete data.amount;
          http.fetch(`/products/edit-prices`, data, false, null, "Edit prices");
        } else if (row.indexOf("external_") === 0) {
          val.external_category_id = val.id;
          let data = Object.assign({}, val);
          data.external_category_id = val.id;
          data.id = id;
          http.fetch(
            `/products/edit-external`,
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("minimum_stock_") === 0) {
          let data = {
            id: id,
            minimum_stock: val,
            warehouse: row.replace("minimum_stock_", ""),
          };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-warehouse?`),
            data,
            false,
            null,
            "Edit products"
          );
        } else if (row.indexOf("is_sale_") === 0) {
          let data = {
            id: id,
            is_sale: val,
            warehouse: row.replace("is_sale_", ""),
          };
          if (this.selectAll && (this.q.length ?? 0) < 1) {
            delete data["id"];
            data["multiple"] = 1;
            data["status"] = this.statuses[this.status].id;
          }
          http.fetch(
            this.setFilterUrl(`/products/${this.type}/edit-warehouse?`),
            data,
            false,
            null,
            "Edit products"
          );
        }
        if (Array.isArray(id)) {
          for (const id1 of id) {
            this.productsManager.byVariantId[id1][row] = val;
          }
        }
      }
    },
    orderBy(order) {
      this.productsManager.reset();
      this.order = order;
      this.setProducts(this.statuses[this.status].id);
    },
    setComponent(data, key, component) {
      data.rows[key].component = markRaw(
        defineAsyncComponent(() =>
          import(`../../components/lists/listItems/${component}.vue`)
        )
      );
    },
    setFilterUrl(url) {
      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      return url;
    },
    setProducts(status, nextPage = false) {
      const loadId = (this.loadId = Math.random());
      if (!nextPage) {
        this.productsManager.reset();
        this.page = 0;
      }
      this.page++;

      http.fetch(this.getUrl(status)).then((data) => {
        if (loadId !== this.loadId) {
          return;
        }
        this.showedNumber = data.to;
        this.totalNumber = data.total;
        if (nextPage) {
          for (const row of data.data) {
            this.productsManager.addProduct(row);
          }
          this.canLoadMore = data.data.length > 0;
          this.loadInProcess = false;
          return;
        }
        this.canLoadMore = true;
        this.loadInProcess = false;
        if (this.firstLoad) {
          this.productsManager.addMediaServer(this.mediaUrl);
          this.productsManager.addlanguages(this.languages);
          this.productsManager.addCurrencies(data.currencies);
          this.productsManager.addWarehouses(this.warehouses);
        }

        if (this.firstLoad) {
          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;
        }

        for (const key in data.rows) {
          data.rows[key].name = data.rows[key].row_name;
          data.rows[key].key = data.rows[key].row_key;
          if (typeof data.rows[key].component === "string") {
            let c = data.rows[key].component;
            delete data.rows[key].component;
            this.setComponent(data, key, c);
          } else {
            delete data.rows[key].component;
          }
          if (typeof data.rows[key].enums === "string") {
            data.rows[key].values = JSON.parse(data.rows[key].enums);
          }
          delete data.rows[key].enums;
          if (typeof data.rows[key].just_in_type === "string") {
            data.rows[key].justInType = JSON.parse(data.rows[key].just_in_type);
          }
          delete data.rows[key].just_in_type;

          if (data.rows[key].could_hidden === false) {
            data.rows[key].show = true;
          } else {
            data.rows[key].show =
              this.lineOptions[0].rows.indexOf(data.rows[key].row_key) > -1;
          }
        }

        if (this.firstLoad) {
          let cat = data.rows.filter((e) => e.key === "category_id")[0];
          cat.values = this.categories;
          cat.allValues = this.allCategories;

          if (this.statuses[this.status].id === 3) {
            for (const row of data.rows) {
              if (row.row_key === "serial") {
                row.editable = true;
              }
            }
          }

          this.rows = data.rows;
          if (this.copyableRows?.length === 0) {
            for (const row of this.rows) {
              if (row.row_key.indexOf("ingredients_") === 0) {
                this.copyableRows.push({
                  type: "ingredient",
                  name: row.row_name,
                  id: row.row_key.replace("ingredients_", ""),
                });
                this.productsManager.addIngredient(row.row_key);
              } else if (row.row_key.indexOf("external_") === 0) {
                this.productsManager.addExternal(row.row_key);
              } else if (row.row_key.indexOf("sell_price_") === 0) {
                this.copyableRows.push({
                  type: "sell_price",
                  name: row.row_name,
                  currency: row.row_key.replace("sell_price_", ""),
                });
                this.productsManager.addIngredient(row.row_key);
              } else if (row.row_key.indexOf("discount_price_") === 0) {
                this.copyableRows.push({
                  type: "discount_price",
                  name: row.row_name,
                  currency: row.row_key.replace("discount_price_", ""),
                });
                this.productsManager.addIngredient(row.row_key);
              } else if (row.row_key === "product_manufacturing_operations") {
                this.copyableRows.push({
                  type: "operations",
                  name: row.row_name,
                });
              }
            }
          }
        }

        this.setCharacteristics().then(() => {
          this.tableKey++;
          for (const row of data.data) {
            this.productsManager.addProduct(row);
          }
          if (data.data.length === 0) {
            this.productsManager.products = [];
          }
          this.dynamicKey++;
        });
        if (this.page === 1) {
          this.loadMore();
        }
      });
    },
    characteristicsValues(vals) {
      vals.push({
        id: -1,
        value: "(empty)",
      });
      return vals;
    },
    setCharacteristics() {
      return new Promise((resolve, reject) => {
        if (this.characteristics.length > 0 && !this.firstLoad) {
          resolve();
          return;
        }

        http
          .fetch(
            `/products/${
              this.type === "materials" ? "materials" : "products"
            }/characteristics?language=en`
          )
          .then((data) => {
            for (const key in data) {
              data[key].filter = "";
            }
            this.characteristics = data;
            this.productsManager.addCharacterics(this.characteristics);

            this.setFilters(data);
            this.firstLoad = false;

            for (const row of this.characteristics) {
              if (row.type === "string" || row.type === "color") {
                let cs = this.rows.filter(
                  (e) => e.key === "characteristics_" + row.id
                )[0];
                if (cs === undefined) {
                  continue;
                }

                cs.selectKey = "id";
                cs.label = "value";
                cs.values = row.characteristics_values;
                cs.createOption = (e) => {
                  for (const obj of row.characteristics_values) {
                    if (obj.value.toLowerCase() === e.toLowerCase()) {
                      return obj;
                    }
                  }
                  this.newCharastericsValue = e;
                  return { id: 0, value: e };
                };
              }
            }

            resolve();
          })
          .catch((e) => reject(e));
      });
    },
    categoryFilters() {
      let categories = "";
      if (Object.keys(this.filters).length > 0) {
        if (this.filters?.category_id?.value) {
          for (const value of this.filters.category_id.value) {
            categories += `&category[]=${value}`;
          }
        }
      }
      http
        .fetch(
          `/products/${
            this.type === "materials" ? "materials" : "products"
          }/characteristics?language=en${categories}&status=${
            this.statuses[this.status].id
          }`
        )
        .then((data) => {
          this.noReloadFilters = true;
          let filterRefs = {};
          for (const obj of data) {
            filterRefs["characteristics_" + obj.id] = obj;
          }
          for (const obj of this.filterOptions) {
            if (filterRefs[obj.key] !== undefined) {
              if (
                filterRefs[obj.key]?.characteristics_values !== null &&
                filterRefs[obj.key]?.characteristics_values !== undefined
              ) {
                obj.values = this.characteristicsValues(
                  filterRefs[obj.key]?.characteristics_values
                );
              } else {
                obj.values = null;
              }
            }
          }
          setTimeout(() => {
            this.noReloadFilters = false;
          }, 50);
        });
    },
    setFilters(data) {
      this.filterOptions = [];
      this.filterOptions.push({
        name: "Category",
        type: "string",
        key: "category_id",
        value: {},
        values: this.categories,
        valueName: "name",
      });
      for (const obj of data) {
        let max =
          obj.characteristics_values_max_val_double !== null
            ? obj.characteristics_values_max_val_double
            : obj.characteristics_values_max_val_int;
        this.filterOptions.push({
          name: obj.name,
          type: obj.type,
          key: "characteristics_" + obj.id,
          value: obj.type === "number" ? [0, max] : {},
          max: max,
          values:
            obj?.characteristics_values !== null &&
            obj?.characteristics_values !== undefined
              ? this.characteristicsValues(obj?.characteristics_values)
              : null,
          prefix: obj?.prefix,
          postfix: obj?.postfix,
        });
      }

      this.filterOptions.push({
        name: "Partners",
        type: "string",
        key: "partners",
        valueName: "name",
        value: {},
        values: this.pricePartners,
      });
      this.filterOptions.push({
        name: "Tags",
        type: "string",
        key: "tags",
        valueName: "name",
        value: {},
        values: this.tags,
      });
      this.filterOptions.push({
        name: "Operations",
        type: "string",
        key: "operations",
        valueName: "name",
        value: {},
        values: this.operations,
      });

      this.$store.commit(
        "setFilterPage",
        "products_" + this.type + "_" + this.status
      );
      this.$store.commit("setFilterOptions", this.filterOptions);
      this.$store.commit("setFilterOptionsStock", true);
      this.$store.commit("setFilterOptionsPrice", true);
    },
    setUpCategories(data, str = "") {
      if (data.id > 0) {
        this.allCategories.push({
          name: `${str}${data.name}`,
          top: data.name,
          sub: str,
          id: data.id,
        });
      }
      if (data.categories !== undefined) {
        for (const category of data.categories) {
          this.setUpCategories(
            category,
            data.name !== undefined ? `${str}${data.name} - ` : ""
          );
        }
      } else {
        this.categories.push({
          name: `${str}${data.name}`,
          top: data.name,
          sub: str,
          id: data.id,
        });
      }
    },
    allVariants(arr, i) {
      const k = Object.keys(this.selectedCharacteristics);
      if (k.length === i) {
        this.possibleVariants.push({ checked: true, values: arr });
        return;
      }
      for (const val of this.selectedCharacteristics[k[i]]) {
        let actArr = arr.slice();
        actArr.push(val);
        this.allVariants(actArr, i + 1);
      }
    },
    addNewCharactheristicsValue(cs) {
      if (cs.type === "string" || cs.type === "color") {
        let send = { id: cs.id, value: { en: cs.filter } };
        http
          .fetch(
            `/products/${
              this.type === "materials" ? "materials" : "products"
            }/characteristics/add-value`,
            send
          )
          .then((data) => {
            cs.characteristics_values.push(data);
            cs.filter = "";
          });
      }
    },
    addPossibleVariant(ok, cs, value) {
      if (ok) {
        if (this.selectedCharacteristics[cs.id] === undefined) {
          this.selectedCharacteristics[cs.id] = [];
        }
        this.selectedCharacteristics[cs.id].push(value);
        this.possibleVariants = [];
        this.allVariants([], 0);
      } else {
        this.selectedCharacteristics[cs.id].splice(
          this.selectedCharacteristics[cs.id].indexOf(value),
          1
        );
        this.possibleVariants = [];
        this.allVariants([], 0);
      }
    },
    addNewRow() {
      this.addNewModal = new bootstrap.Modal(this.$refs.addNewModal, {
        backdrop: "static",
      });
      this.addNewModal.show();
    },
    deleteRows() {
      let ids = this.selectedItems.flatMap((e) => {
        /*
        if (e.variants !== undefined) {
          return e.variants.flatMap((s) => {
            return {
              id: s.id,
              is_variant: s.type === products.TYPE_VARIANT ? 1 : 0,
            };
          });
        }
        */
        return {
          id: e.id,
          is_variant: e.type === products.TYPE_VARIANT ? 1 : 0,
        };
      });

      const _this = this;
      http.fetch(`/products/delete`, { rows: ids }, true).then((data) => {
        for (const id of data.deleted) {
          let element = null;
          if (id.is_variant) {
            element = this.productsManager.byVariantId[id.id];
          } else {
            element = this.productsManager.byProductId[id.id];
          }
          if (element !== undefined && element !== null) {
            element.hideSuccess = true;
            setTimeout(() => {
              _this.deleteRow(id, element);
            }, 1200);
          }
        }
        for (const id of data.errors) {
          let element = null;
          if (id.is_variant) {
            element = this.productsManager.byVariantId[id.id];
          } else {
            element = this.productsManager.byProductId[id.id];
          }
          if (element !== undefined && element !== null) {
            element.errorNotify = true;
          }
        }
      });
    },
    deleteRow(id, element) {
      if (id.is_variant) {
        let elements =
          this.productsManager.byProductId[element?.product?.id]?.variants;

        for (const v of elements) {
          let index = v.variants.findIndex((e) => e.id === element.id);
          if (index !== undefined && index !== null && index !== -1) {
            v.variants.splice(index, 1);
          }
        }
      } else {
        let index = this.productsManager.products.findIndex(
          (e) => e.id === element.id
        );
        if (index !== -1) {
          this.productsManager.products.splice(index, 1);
        }
      }
      this.dynamicKey++;
    },
    nextSerial() {
      http
        .fetch(
          `/products/${
            this.type === "materials" ? "materials" : "products"
          }/next-serial`,
          {
            category_id: this.newProduct.category_id,
          }
        )
        .then((data) => {
          this.newProduct.serial = data.serial;
        });
    },
    pin(id, pinned) {
      let i = this.productsManager.products.findIndex((e) => e.id === id);
      let data = this.productsManager.products[i];

      if (!pinned) {
        this.productsManager.products.splice(i, 1);
        this.productsManager.products.push(data);
      } else {
        this.productsManager.products.splice(i, 1);
        this.productsManager.products.unshift(data);
      }

      this.tableKey++;

      http.fetch(`/products/pin`, { id: id });
    },
    addNew() {
      this.loadAddBtn = true;
      let post = {
        name: this.newProduct.name,
        serial: this.newProduct.serial,
        unit: this.newProduct.unit ?? null,
        variants: [],
      };
      if (this.newProduct.category_id.indexOf("____") === 0) {
        post["new_category"] = this.newProduct.category_id.replace("____", "");
      } else {
        post["category"] = this.newProduct.category_id;
      }

      for (const variant of this.possibleVariants) {
        if (variant.checked) {
          let k = [];
          for (const val of variant.values) {
            k.push(val.id);
          }
          post.variants.push(k);
        }
      }

      http
        .fetch(
          `/products/${
            this.type === "materials" ? "materials" : "products"
          }/add-product`,
          post
        )
        .then((data) => {
          data.selected = this.selectAll;
          this.productsManager.addProduct(data, true);
          this.addNewModal.hide();
          this.loadAddBtn = false;
          this.tableKey++;
          this.dynamicKey++;
          if (this.selectAll) {
            this.selectAllDiff++;
          }
          this.newProduct = {
            category: null,
            name: "",
            serial: "",
          };
          this.possibleVariants = [];
          this.possibleVariantCharasterics = {};
          this.selectedCharacteristics = [];
        })
        .catch((data) => {
          this.loadAddBtn = false;
          this.store.commit("setApiError", data);
        });
    },
  },
  components: {
    ManualProductRecommendations,
    CopyProducts,
    BaseIcon,
    NewManualIncoming,
    DynamicTable,
    ConfirmModal,
    TopBar,
  },
};
</script>
